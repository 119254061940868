import React, {FunctionComponent} from 'react'
import {CategoryColours, ProjectColours} from '@open-law/open-law-shared'
import {BlogCategory, CategoryWithBlogs} from '../../../types/vr-blog'
import {setBackgroundColourStyles, setTextColourStyles} from './vr-blog-colors'

interface VrBlogsByCategoryProps {
    data: CategoryWithBlogs[]
    categories: Map<string, BlogCategory>
}

const VrBlogsByCategory: FunctionComponent<VrBlogsByCategoryProps> = (props) => {

    // console.log('VR Blog By Category Component Data: ', props.data)
    const {data, categories} = props

    const renderCategoryBubble = (bubbleTitle: string, parentCategory: string, categories:any) => {
        const titleAndParentDoNotMatch: boolean = (parentCategory !== 'featured') && (bubbleTitle !== 'featured')
        const isFeatured: boolean = bubbleTitle === 'Featured' ? true : false
        const bubbleCategoryColor: string = [...categories.values()].find((cat) => cat.title === bubbleTitle).categoryColour

        return !titleAndParentDoNotMatch && !isFeatured ? (
             <div className={`flex items-center justify-center bg-heading-green rounded-md pt-1 pb-1 w-20 mb-3`}>
                <h3 className="text-white text-center text-xs">
                    {bubbleTitle}
                </h3>
            </div>

        ) : null
    }

    const renderBlogCategory = (
        category: CategoryWithBlogs,
        colour: ProjectColours
    ) => {
        const categoryColour: string = categories.has(category.fieldValue!) ? categories.get(category.fieldValue!)!.categoryColour : 'green'

        return (
            <section key={category.fieldValue}>
                <header className="pl-8">
                    {
                        category.fieldValue ? (
                            <>
                                <h2 className="font-medium text-3xl mb-2">
                                    {categories.has(category.fieldValue) ? categories.get(category.fieldValue)!.title : null}
                                </h2>

                                <div className={`bg-heading-green h-1 w-32 mb-2`}>
                                </div>

                                <p>
                                    {
                                        categories.has(category.fieldValue) ? categories.get(category.fieldValue)!.description : null
                                    }
                                </p>
                            </>
                        ) : null
                    }
                </header>

                <div className={'p-8 w-full flex flex-wrap justify-start gap-4'}>{
                    category.nodes
                        .map(
                            (post, idx) => (
                                <a key={`${post}${idx}`}
                                   href={`/victorian-reports/blog/posts/${post.slug.current}`}
                                   className={`flex flex-col justify-between shadow-lg bg-white hover:bg-gray-100 h-72 w-64 p-6`}>

                                    <div>
                                        {
                                            post.categories.map((cat) => renderCategoryBubble(cat.title, category.fieldValue, props.categories)
                                            )
                                        }


                                        <h2 className="text-white font-bold text-2xl">
                                            {
                                                typeof post !== 'string' ? (
                                                    <>
                                                        {
                                                            post.title === 'Read more...' ? post.title : (
                                                                <>
                                                            <span className="text-black font-semibold">
                                                                {post.title}
                                                            </span>

                                                                    <br/>
                                                                    {
                                                                        post.subTitle ? (
                                                                            <span
                                                                                className="text-black font-normal text-lg">
                                                                        {post.subTitle}
                                                                    </span>
                                                                        ) : null
                                                                    }

                                                                </>

                                                            )
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            post === 'Read more...' ? post : (
                                                                <>
                                                                    {post}
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )}
                                        </h2>

                                    </div>
                                    <div>
                                        <p className={`text-heading-green text-right text-4xl`}>
                                            →
                                        </p>

                                    </div>
                                </a>
                            )
                        )
                }
                    <a href={`/victorian-reports/blog/category/${category.fieldValue}`}
                       className={`bg-white h-64 w-64 p-6`}>

                        <h2 className={`mt-28 text-center text-heading-green font-normal text-xl`}>
                            Read more →
                        </h2>


                    </a>
                </div>
            </section>
        )
    }


    return (
        <div className="max-w-7xl h-auto justify-self-start">
            {/*<div className="w-full flex justify-start gap-4 p-8 sticky top-0 bg-white">
                {
                    Array.from(['Mentions', 'Publication Updates', 'Case Notes']).map((text) =>
                        (
                            <button
                                className={`${text === 'Mentions' ? 'bg-gray-800' : 'bg-heading-green'} hover:bg-gray-800 px-3 py-2 text-white`}>
                                {text}
                            </button>
                        )
                    )
                }
            </div>*/}
            <div className="pt-8">
                {
                    data ? data.map((cat) => renderBlogCategory(cat, 'green')) : null
                }
            </div>
        </div>
    )
}

export default VrBlogsByCategory
