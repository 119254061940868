import React, {FunctionComponent} from 'react'
import {ProjectColours} from '@open-law/open-law-shared'
import {BlogCategory, CategoryWithBlogs} from '../../../types/vr-blog'
import Pagination from './Pagination'
import {setBackgroundColourStyles, setTextColourStyles} from './vr-blog-colors'

interface VrBlogCategoryProps {
    data: CategoryWithBlogs
    categories: Map<string, BlogCategory>
    currentPage: number
    numberOfPages: number
    skip: number
    limit: number
    categoryColor: string
}

const VrBlogCategory: FunctionComponent<VrBlogCategoryProps> = (props) => {

    // console.log('VR Blog By Category Component Props: ', props)
    const {data, categories, currentPage, numberOfPages, skip, limit, categoryColor} = props

    const pageContext: { currentPage: number, numberOfPages: number, path: string } = {
        currentPage: 0,
        numberOfPages: 0,
        path: "victorian-reports/blog/category/"
    }
    pageContext.currentPage = currentPage
    pageContext.numberOfPages = numberOfPages
    pageContext.path = `victorian-reports/blog/category/${data.categoryName}`

    const renderBlogCategory = (
        category: CategoryWithBlogs,
        colour: ProjectColours
    ) => {
        // console.log( category, categories.get(category.categoryName) )
        return (
            <section>
                <header className="pl-8">
                    {
                        category.categoryName ?
                            (
                                <>
                                    <h2 className="font-medium text-4xl mb-4">
                                        {
                                            categories.has(category.categoryName) ? categories.get(category.categoryName).title : null
                                        }
                                    </h2>
                                    <p>
                                        {
                                            categories.has(category.categoryName) ? categories.get(category.categoryName).description : null
                                        }
                                    </p>
                                </>
                            ) : null
                    }

                </header>

                <div className={'p-8 w-full flex flex-wrap justify-start gap-4'}>{

                    category.edges.slice(skip, limit).map(
                        (post, idx) => (
                            <a key={`${post.node.title}${idx}`}
                               href={`/victorian-reports/blog/posts/${post.node.slug.current}`}
                               className={`flex flex-col justify-between shadow-lg bg-white hover:bg-gray-100 h-72 w-64 p-6`}>
                                <div>
                                    <h2 className="text-white font-bold text-2xl">
                                        {
                                            typeof post !== 'string' ? (
                                                <>
                                                    {
                                                        post.title === 'Read more...' ? post.title : (
                                                            <>
                                                                <span className="text-black font-semibold">
                                                                {post.node.title}
                                                            </span><br/>
                                                                {
                                                                    post.node.subTitle ? (
                                                                        <span
                                                                            className="text-black font-normal text-lg">
                                                                        {post.node.subTitle}
                                                                            </span>
                                                                    ) : null
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        post === 'Read more...' ? post : (
                                                            <>
                                                                {post}
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )}
                                    </h2>
                                </div>
                                <div>

                                    <p className={`${setTextColourStyles(props.categoryColor)} text-right text-4xl`}>
                                        →
                                    </p>


                                </div>
                            </a>
                        )
                    )
                }
                </div>
            </section>
        )
    }


    const renderPaginationNumber = (pageNumber: number, currentPage: number) => {
        return <a href={`/blog${pageNumber > 1 ? `/${pageNumber}` : ''}`}
                  className={`${pageNumber === currentPage ? 'border-blue-400' : 'text-gray-500 hover:border-gray-300'} border-t-2 border-transparent pt-4 px-4 inline-flex items-center text-sm font-medium hover:text-gray-700 `}>
            {pageNumber}
        </a>
    }

    return (
        <div className="max-w-7xl h-auto justify-self-start">
            {/*<div className="w-full flex justify-start gap-4 p-8 sticky top-0 bg-white">
                {
                    Array.from(['Mentions', 'Publication Updates', 'Case Notes']).map((text) =>
                        (
                            <button
                                className={`${text === 'Mentions' ? 'bg-gray-800' : 'bg-heading-green'} hover:bg-gray-800 px-3 py-2 text-white`}>
                                {text}
                            </button>
                        )
                    )
                }
            </div>*/}
            <div className="pt-8 ">
                {
                    data ? renderBlogCategory(data, 'green') : null
                }
            </div>

            <Pagination currentPage={pageContext.currentPage} numberOfPages={pageContext.numberOfPages}
                        pathPrefix={pageContext.path} pageName={data.categoryName}/>
            {/*Pagination*/}
            {/*            <footer>
                <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                    <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
                        <nav className="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0">
                            <div className="-mt-px w-0 flex-1 flex">
                                {
                                    pageContext.currentPage === 2 ?
                                    <a
                                        className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                        href={`/blog`}>
                                        <svg className="mr-3 h-5 w-5 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                        Previous
                                    </a> :
                                    pageContext.currentPage > 1 &&
                                        <a className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                           href={`/blog/${pageContext.currentPage - 1}`}>Previous</a>
                                }
                            </div>
                            <div className="hidden md:-mt-px md:flex">
                                {
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                                        .map((n) => renderPaginationNumber(n, pageContext.currentPage))
                                }
                            </div>
                            <div className="-mt-px w-0 flex-1 flex justify-end">
                                {
                                    pageContext.currentPage < pageContext.numberOfPages && (
                                        <a href={`/blog/${pageContext.currentPage + 1}`}
                                           className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                                            Next
                                            <svg className="ml-3 h-5 w-5 text-gray-400"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd"
                                                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                                      clip-rule="evenodd"/>
                                            </svg>
                                        </a>
                                    )
                                }
                            </div>
                        </nav>
                    </div>
                </div>
            </footer>*/}
        </div>
    )
}

export default VrBlogCategory
