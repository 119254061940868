import React, {FunctionComponent} from 'react'
import VrSideMenu                 from './vr-side-menu'
import VrBlogFooter               from './vr-blog-footer'
import VrBlogMobileMenu from './vr-blog-mobile-menu'
import {BlogPageType}   from '../../../types/vr-blog'

interface VrBlogLayoutProps {
    pageType: BlogPageType
}

const VrBlogLayout: FunctionComponent<VrBlogLayoutProps> = (props) => {

    const {children} = props

    return (
        <>
            <VrBlogMobileMenu pageType={props.pageType} />

            <div style={{maxWidth: '1536px'}} className={'lg:grid lg:grid-cols-sidebar-w-content'}>
                <VrSideMenu/>
                <main className="justify-self-start">
                    {children}
                </main>
            </div>
            <VrBlogFooter/>
        </>
    )
}

export default VrBlogLayout
