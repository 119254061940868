
export const setBackgroundColourStyles = (colour: string) => {
    switch (colour) {
        case 'blue':
            return 'bg-blue-250'
        case 'green':
            return 'bg-green-case-notes'
        case 'red':
            return 'bg-red-400 '
        case 'purple':
            return 'bg-purple-400 '
        case 'black':
            return 'bg-heading-green'
    }
}

export const setTextColourStyles = (colour: string) => {
    switch (colour) {
        case 'blue':
            return 'text-blue-250'
        case 'green':
            return 'text-green-case-notes'
        case 'red':
            return 'text-red-400 '
        case 'purple':
            return 'text-purple-400 '
        case 'black':
            return 'text-heading-green'
    }
}
