import React, {FunctionComponent} from 'react'
import LwbLogo                    from '../../assets/logos/lwb-logo-white.svg'

interface VrBlogFooterProps {
}

const VrBlogFooter: FunctionComponent<VrBlogFooterProps> = (props) => (
    <footer className="bg-gray-800" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">Footer</h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
                <div className="space-y-8 xl:col-span-1">
                    <a target="_blank" href={'https://littlewilliambourke.com.au'}>
                        <img className="h-16" src={LwbLogo}
                             alt="Open Law Banner"/>
                    </a>
                    <p className="text-gray-500 text-base">
                        Little William Bourke - or Little Bill - is the commercial entity which publishes, produces and
                        distributes the Victorian Reports in Australia.
                    </p>
                    <div className="flex space-x-6">

                        <a href="mailto:sales@victorianreports.com.au" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Email</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                            </svg>
                        </a>


                        {/*                        <a href="#" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Instagram</span>
                            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                      clipRule="evenodd" />
                            </svg>
                        </a>
                        */}


                        <a href="https://twitter.com/VicLawReports" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Twitter</span>
                            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/victorian-reports"
                           className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">LinkedIn</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="h-6 w-6">
                                <path
                                    d="M29.63.001H2.362C1.06.001 0 1.034 0 2.306V29.69C0 30.965 1.06 32 2.362 32h27.27C30.937 32 32 30.965 32 29.69V2.306C32 1.034 30.937.001 29.63.001z"
                                    fill="transparent"/>

                                <path
                                    d="M4.745 11.997H9.5v15.27H4.745zm2.374-7.6c1.517 0 2.75 1.233 2.75 2.75S8.636 9.9 7.12 9.9a2.76 2.76 0 0 1-2.754-2.753 2.75 2.75 0 0 1 2.753-2.75m5.35 7.6h4.552v2.087h.063c.634-1.2 2.182-2.466 4.5-2.466 4.806 0 5.693 3.163 5.693 7.274v8.376h-4.743V19.84c0-1.77-.032-4.05-2.466-4.05-2.47 0-2.85 1.93-2.85 3.92v7.554h-4.742v-15.27z"
                                    fill="#fff"/>
                            </svg>

                        </a>
                        {/*                        <a href="#" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">GitHub</span>
                            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                                      clipRule="evenodd" />
                            </svg>
                        </a>*/}

                        {/*<a href="#" className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Dribbble</span>
                            <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                                      clipRule="evenodd" />
                            </svg>
                        </a>*/}
                    </div>
                </div>
                <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 col-span-2">
                    <div>
                        <a href="/projects/victorian-reports" className="">
                            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                The Victorian Reports
                            </h3>
                        </a>
                        <ul className="mt-4 space-y-4">
                            <li>
                                <a href="https://victorianreports.com.au"
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    victorianreports.com.au
                                </a>
                            </li>
                            <li>
                                <a href="https://victorianreports.com.au/subscribe" target={'_blank'}
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    Buy or Subscribe
                                </a>
                            </li>
                            <li>
                                <a href="https://victorianreports.com.au/browse/recent-reports"
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    Browse Recent VRs
                                </a>
                            </li>
                            <li>
                                <a href="/victorian-reports/contact-us"
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    Contact Us
                                </a>
                            </li>
                            {/*                            <li>
                                <a href="/projects/publication-of-secondary-law"
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    Publication of Secondary Law
                                </a>
                            </li>

                            <li>
                                <a href="/projects/one-to-100"
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    One to 100
                                </a>
                            </li>*/}
                        </ul>
                    </div>
                    <div className="mt-12 md:mt-0">
                        <a href="/about" className="">
                            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                Other Open Law Initiatives
                            </h3>
                        </a>
                        <ul className="mt-4 space-y-4">
                            <li>
                                <a href="https://openlaw.org.au/projects/jade"
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    JADE
                                </a>
                            </li>

                            <li>
                                <a href="https://resources.judicialcollege.vic.edu.au/article/669236"
                                   className="text-base text-gray-500 hover:text-gray-200">
                                    Victorian Sentencing Manual
                                </a>
                            </li>

                            <li>
                                <a href="/events" className="text-base text-gray-500 hover:text-gray-200">
                                    Open Law Training and Events
                                </a>
                            </li>

                        </ul>
                    </div>
                    {/*

                    <div className="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                Resources
                            </h3>
                            <ul className="mt-4 space-y-4">
                                <li>
                                    <a href="/learn" className="text-base text-gray-500 hover:text-gray-200">
                                        Learn Legal Research / CPD
                                    </a>
                                </li>

                                <li>
                                    <a href="/tools" className="text-base text-gray-500 hover:text-gray-200">
                                        Explore Tools
                                    </a>
                                </li>

                                <li>
                                    <a href="/guides" className="text-base text-gray-500 hover:text-gray-200">
                                        Guides
                                    </a>
                                </li>

                                <li>
                                    <a href="/webinars" className="text-base text-gray-500 hover:text-gray-200">
                                        Webinars
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-12 md:mt-0">
                            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                                Contact Us
                            </h3>
                            <ul className="mt-4 space-y-4">
                                <li>
                                    <a href="/privacy" className="text-base text-gray-500 hover:text-gray-200">

                                    </a>
                                </li>
                                <li>
                                    <a href="/terms" className="text-base text-gray-500 hover:text-gray-200">
                                        Terms
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

*/}
                </div>
            </div>
            <div className="mt-12 border-t border-gray-200 pt-8">
                <p className="text-base text-gray-400 xl:text-center">
                    © 2016-{new Date().getFullYear()} Little William Bourke (ABN 65 610 951 089).
                    Little William Bourke is a wholly owned subsidiary of BarNetwork Pty Limited. (ABN 32 092 121 198).
                </p>
            </div>
        </div>
    </footer>
)

export default VrBlogFooter
