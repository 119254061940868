import React, {useState} from "react";

function PaginationBar(props) {

    const {currentPage, numberOfPages, pathPrefix, pageName} = props;

    const renderPaginationNumber = (pageNumber: number, currentPage: number) => {
        return <a href={`/${pathPrefix}${pageNumber > 1 ? `/${pageNumber}` : ''}`}
                  className={`${pageNumber === currentPage ? 'border-blue-400' : 'text-gray-500 hover:border-gray-300'} border-t-2 border-transparent pt-4 px-4 inline-flex items-center text-sm font-medium hover:text-gray-700 `}>
            {pageNumber}
        </a>
    }

    let pages = []

    for (let i = 1; i <= numberOfPages; i++) {
        pages.push(i)
    }

    return (
        <footer>
            <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
                    <nav className={`border-t ${currentPage === numberOfPages ? "border-white" : "border-gray-200"} px-4 flex items-center justify-between sm:px-0`}>
                        <div className="-mt-px w-0 flex-1 flex">
                            {
                                currentPage === 2 ?
                                    <a
                                        className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                        href={`/victorian-reports/blog/category/${pageName}`}>
                                        <svg className="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                        Previous
                                    </a> :
                                    currentPage > 2 &&
                                    <a className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                       href={`/victorian-reports/blog/category/${pageName}/${currentPage - 1}`}>Previous</a>
                            }
                        </div>
                        <div className="hidden md:-mt-px md:flex">
                            {
                                // [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]


                                pages.map((n) => renderPaginationNumber(n, currentPage))
                            }
                        </div>
                        <div className="-mt-px w-0 flex-1 flex justify-end">
                            {
                                currentPage < numberOfPages && (
                                    <a href={`/victorian-reports/blog/category/${pageName}/${currentPage + 1}`}
                                       className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300">
                                        Next
                                        <svg className="ml-3 h-5 w-5 text-gray-400"
                                             xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                                  clip-rule="evenodd"/>
                                        </svg>
                                    </a>
                                )
                            }
                        </div>
                    </nav>
                </div>
            </div>
        </footer>
    );
}

export default PaginationBar;
