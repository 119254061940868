import React, {FunctionComponent} from 'react'
import VrLogo                     from '../../assets/logos/vr-logo.svg'


interface VrSideMenuProps {

}

const VrSideMenu: FunctionComponent<VrSideMenuProps> = (props) => {

    const {} = props

    return (
        <nav className="hidden lg:flex max-w-sm">
            <div className="bg-heading-green p-4 pt-14 flex flex-col justify-between h-screen sticky top-0">
                <header className="p-4 w-80">
                    <h1 className="sr-only">Victorian Reports</h1>
                    <img alt={'Logo of the Victorian Reports'} className="w-80 " src={VrLogo}/>
                    <p className="mt-12 text-white font-medium text-center tracking-wider">
                        The latest news regarding<br/> the authorised law reports of the<br/> Supreme Court of Victoria
                    </p>
                    <nav
                        className="flex flex-col gap-4 text-white text-lg font-normal text-left content-center ml-14 mt-16">
                        <h2 className="text-sm font-thin">
                            MENU
                        </h2>
                        <a href={'/victorian-reports/blog'}>
                            Home
                        </a>
                        <a href={'/victorian-reports/blog/posts'}>
                            All Posts
                        </a>
                        <a href={'https://victorianreports.com.au/subscribe'}>
                            Subscribe
                        </a>

                        <div className="bg-white h-0.5 w-52 my-2">
                        </div>
                        <a href={'/victorian-reports/contact-us'}>Contact Us</a>

                    </nav>
                </header>
            </div>
        </nav>
    )

}

export default VrSideMenu
