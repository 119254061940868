import VrBlogLayout                                      from './vr-blog-layout'
import React, {FunctionComponent}                        from 'react'
import VrBlogsByCategory                                 from './vr-blogs-by-category'
import VrBlogPost                                    from './vr-blog-post'
import {BlogCategory, BlogPageContext, BlogPageData} from '../../../types/vr-blog'
import {graphql}                                     from 'gatsby'
import VrBlogPostList                                    from './vr-blog-post-list'
import VrBlogCategory                                    from './vr-blog-category'
import VrBlogContactUs                                   from './vr-blog-contact-us'

interface VrBlogPageProps {
    pageContext: BlogPageContext // data passed into context in gatsby-node
    data: BlogPageData // data from the graphql query on this page
}

export const vrBlogPageQuery = graphql`
query VrBlogPageQuery($categorySlug: String, $postId: String, $useFrontPageCategoryQuery: Boolean = false, $useSingleCategoryQuery: Boolean = false, $useAllPostsQuery: Boolean = false, $useSinglePostQuery: Boolean = false, $limit: Int, $skip: Int) {
  categoryWithPosts: allSanityPost(
    filter: {categories: {elemMatch: {slug: {current: {eq: $categorySlug}}}}, slug: {current: {ne: null}}, publishTo: {eq: "victorian-reports-blog"}}
    sort: {fields: publishedAt, order: DESC}
  ) {
    edges @include(if: $useSingleCategoryQuery) {
      node {
        slug {
          current
        }
        publishedAt
        publishTo
        authors {
          name
        }
        title
        subTitle
        categories {
          title
          slug {
            current
          }
        }
      }
    }
  }
  categories: allSanityCategory {
    edges {
      node {
        title
        slug {
          current
        }
        description
        categoryColour
      }
    }
  }
  postsByCategory: allSanityPost(
    filter: {slug: {current: {ne: null}}, publishTo: {eq: "victorian-reports-blog"}}
    sort: {fields: publishedAt, order: DESC}
  ) {
    group(field: categories___slug___current, limit: 7) @include(if: $useFrontPageCategoryQuery) {
      nodes {
        categories {
          title
          slug {
            current
          }
          categoryColour
        }
        slug {
          current
        }
        publishedAt
        publishTo
        authors {
          name
        }
        title
        subTitle
      }
      fieldValue
    }
  }
  post: sanityPost(id: {eq: $postId}) @include(if: $useSinglePostQuery) {
    title
    subTitle
    publishedAt(formatString: "DD-MM-YYYY")
    mainImage {
      alt
      asset {
        url
      }
    }
    categories {
      title
      slug {
        current
      }
    }
    excerpt: _rawExcerpt(resolveReferences: {maxDepth: 10})
    authors {
      name
      slug {
        current
      }
      title
      image {
        alt
        asset {
          url
        }
      }
      contactEmail
    }
    body: _rawBody(resolveReferences: {maxDepth: 10})
  }
  posts: allSanityPost(
    filter: {slug: {current: {ne: null}}, publishTo: {eq: "victorian-reports-blog"}}
    sort: {fields: publishedAt, order: DESC}
    limit: $limit
    skip: $skip
  ) @include(if: $useAllPostsQuery) {
    edges {
      node {
        title
        subTitle
        slug {
          current
        }
        publishedAt(formatString: "DD-MM-YYYY")
        mainImage {
          alt
          asset {
            url
          }
        }
        excerpt: _rawExcerpt(resolveReferences: {maxDepth: 10})
        authors {
          name
          slug {
            current
          }
          title
          image {
            alt
            asset {
              url
            }
          }
          contactEmail
        }
      }
    }
  }
}
`

const VrBlogPage: FunctionComponent<VrBlogPageProps> = (props) => {

    const {data, pageContext} = props
    // console.log('VR Blog Page', props)

    const createCategoryMap: (categories: { edges: { node: BlogCategory }[] }) => Map<string, BlogCategory> = (categories) => {
        const cats: Map<string, BlogCategory> = new Map()
        data.categories.edges.forEach(({node}) => node?.slug && node?.slug?.current ? cats.set(node.slug.current, node) : null)
        return cats
    }

    const content = (pageContext: BlogPageContext) => {
        let el: JSX.Element | null = null
        // console.log('Component data:', pageContext)
        switch (pageContext.type) {
            case 'contact-us': {
                el = <VrBlogContactUs/>
                break
            }
            case 'all-blogs': {
                el = data.posts ? <VrBlogPostList data={data.posts.edges}/> : null
                break
            }
            case 'blog-post': {
                el = data.post ? <VrBlogPost data={data.post}/> : null
                break
            }
            case 'front-page': {
                const groups = data.postsByCategory?.group.sort(
                    (a, b) => a.fieldValue === 'featured' ? -1 : 1
                )
                const cats: Map<string, BlogCategory> = createCategoryMap(data.categories)

                el = data.postsByCategory ?
                     (
                         <>
                             <h1 className="font-medium text-4xl pl-7 pt-10 mb-2">
                                 The Victorian Reports Blog
                             </h1>
                             <h2 className="text-sm pt-4 pl-7 pb-10">
                                 The latest news regarding the authorised law reports of the Supreme Court of Victoria
                             </h2>
                             <VrBlogsByCategory data={groups} categories={cats}/>

                             <br/>
                             <br/><br/>

                         </>

                     ) : null

                break
            }
            case 'category-page': {
                const cats: Map<string, BlogCategory> = createCategoryMap(data.categories)
                el = data.categoryWithPosts ? (
                    <VrBlogCategory
                        data={{categoryName: pageContext.categorySlug, edges: data.categoryWithPosts.edges}}
                        categories={cats}
                        currentPage={pageContext.currentPage}
                        numberOfPages={pageContext.numberOfPages}
                        skip={pageContext.skip}
                        limit={pageContext.limit}
                        categoryColor={pageContext.categoryColor}
                    />
                ) : null
                break
            }
            default:
                el = <p>Error loading Victorian Reports blog.</p>
                break
        }
        return el
    }
    return (
        <VrBlogLayout pageType={props.pageContext.type}>
            {content(pageContext)}
        </VrBlogLayout>
    )
}

export default VrBlogPage
