import React, {FunctionComponent} from 'react'
import {Disclosure}               from '@headlessui/react'
import {MenuIcon, XIcon}          from '@heroicons/react/outline'

import VrLogo         from '../../assets/logos/vr-logo-black.svg'
import {BlogPageType} from '../../../types/vr-blog'

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

interface VrBlogMobileMenuProps {
    pageType: BlogPageType
}

const VrBlogMobileMenu: FunctionComponent<VrBlogMobileMenuProps> = (props) => {

    const {pageType} = props

    const handleActiveStyleForTabletMenu = (linkName: BlogPageType, pageType: BlogPageType) =>
        linkName === pageType
        ? `border-heading-green text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`
        : `border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`

    const handleActiveStyleForMobileMenu = (linkName: BlogPageType, pageType: BlogPageType) =>
        linkName === pageType
        ? `bg-gray-50 border-heading-green text-heading-green block pl-3 pr-4 py-2 border-l-4 text-base font-medium`
        : `border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium`

    return (
        <Disclosure as="nav" className="block lg:hidden bg-white shadow">
            {({open}) => (
                <>
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-16">
                            <div className="flex">
                                <div className="flex-shrink-0 flex items-center">
                                    <a href="/victorian-reports/blog">
                                        <img
                                            className="block h-8 w-auto"
                                            src={VrLogo}
                                            alt="Victorian Reports logo"
                                        />
                                    </a>
                                </div>
                                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                    {/* Current: "border-heading-green text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                    <a
                                        href="/victorian-reports/blog"
                                        className={handleActiveStyleForTabletMenu('front-page', pageType)}
                                    >
                                        Home
                                    </a>
                                    <a
                                        href="/victorian-reports/blog/posts"
                                        className={handleActiveStyleForTabletMenu('all-blogs', pageType)}
                                    >
                                        All Posts
                                    </a>
                                    <a
                                        href="/victorian-reports/contact-us"
                                        className={handleActiveStyleForTabletMenu('contact-us', pageType)}
                                    >
                                        Contact Us
                                    </a>
                                </div>
                            </div>
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <Disclosure.Button
                                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-heading-green">
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                    ) : (
                                         <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                     )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="pt-2 pb-3 space-y-1">
                            {/* Current: "bg-indigo-50 border-heading-green text-heading-green", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                            <Disclosure.Button
                                as="a"
                                href="/victorian-reports/blog"
                                className={handleActiveStyleForMobileMenu('front-page', pageType)}
                            >
                                Home
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="/victorian-reports/blog/posts"
                                className={handleActiveStyleForMobileMenu('all-blogs', pageType)}
                            >
                                All Posts
                            </Disclosure.Button>
                            <Disclosure.Button
                                as="a"
                                href="/victorian-reports/contact-us"
                                className={handleActiveStyleForMobileMenu('contact-us', pageType)}
                            >
                                Contact Us
                            </Disclosure.Button>
                        </div>
                        <div className="pt-4 pb-3 border-t border-gray-200">
                            <div className="flex justify-evenly items-center px-4">

                                <a href="mailto:sales@victorianreports.com.au" className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">Email</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                    </svg>
                                </a>


                                <a href="https://www.linkedin.com/company/victorian-reports"
                                   className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">LinkedIn</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" className="h-6 w-6">
                                        <path
                                            d="M29.63.001H2.362C1.06.001 0 1.034 0 2.306V29.69C0 30.965 1.06 32 2.362 32h27.27C30.937 32 32 30.965 32 29.69V2.306C32 1.034 30.937.001 29.63.001z"
                                            fill="#9ca3af"/>

                                        <path
                                            d="M4.745 11.997H9.5v15.27H4.745zm2.374-7.6c1.517 0 2.75 1.233 2.75 2.75S8.636 9.9 7.12 9.9a2.76 2.76 0 0 1-2.754-2.753 2.75 2.75 0 0 1 2.753-2.75m5.35 7.6h4.552v2.087h.063c.634-1.2 2.182-2.466 4.5-2.466 4.806 0 5.693 3.163 5.693 7.274v8.376h-4.743V19.84c0-1.77-.032-4.05-2.466-4.05-2.47 0-2.85 1.93-2.85 3.92v7.554h-4.742v-15.27z"
                                            fill="#fff"/>
                                    </svg>

                                </a>

                                <a href="https://twitter.com/VicLawReports"
                                   className="text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">Twitter</span>
                                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                        <path
                                            d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default VrBlogMobileMenu
